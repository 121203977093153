


























import type { Picture, Link } from '@/inc/types'
import { defineComponent, PropType } from '@vue/composition-api'

import UiPrices from '@/components/ui/Prices.vue'
import UiPicture from '@/components/ui/Picture.vue'

interface PriceDetails {
  picture: Picture
  title: string
  htmltext: string
  details: {
    price: string
    architect: string
    worker: string
    dimensions: string
  }
  link: Link
}

export default defineComponent({
  name: 'FlexiblePriceDetails',
  components: { UiPrices, UiPicture },
  props: {
    content: {
      type: Object as PropType<PriceDetails>,
      required: true,
    },
  },

  setup() {
    const detailsIcons = {
      price: 'euro',
      architect: 'pencil-ruler',
      worker: 'hammer',
      dimensions: 'ruler',
    }

    return {
      detailsIcons,
    }
  },
})
